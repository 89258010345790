import React, { useEffect, useState } from 'react';
import {
	Button,
	Stack,
	Typography,
	Box,
	Card,
	CardContent,
} from '@mui/material';
import SelectTemplate from './manageSurveyComponents/SelectTemplate';
import SelectOptions from './manageSurveyComponents/SelectOptions';
import SelectRestaurant from './manageSurveyComponents/SelectRestaurant';
import { useAppContext } from '../../../../context/AppContext.jsx';
import axios from '../../../../services/auth-header.js';
import EnabledSurveySuccessPopup from './manageSurveyComponents/EnabledSurveySuccessPopup.jsx';
import EnabledSurveyFailurePopup from './manageSurveyComponents/EnabledSurveyFailurePopup.jsx';
import dayjs from 'dayjs';

const ManageSurveys = () => {
	const { openToast } = useAppContext();
	const [surveyTemplateId, setSurveyTemplateId] = useState(-1);
	const [frequency, setFrequency] = useState(-1);
	const [dinersPerFrequency, setDinersPerFrequency] = useState(-1);
	const [restaurants, setRestaurants] = useState([]);
	const [canStartSurvey, setCanStartSurvey] = useState(false);
	const [resetSignal, setResetSignal] = useState(false);
	const [showSuccessPopup, setShowSuccessPopup] = useState(false);
	const [showFailurePopup, setShowFailurePopup] = useState(false);
	const [surveyStartDate, setSurveyStartDate] = useState(dayjs(new Date()));
	const [surveyTitle, setSurveyTitle] = useState('');
	const [rewardAmount, setRewardAmount] = useState(1);

	useEffect(() => {
		const checkStartSurvey = () => {
			if (
				surveyTemplateId === -1 ||
				frequency === -1 ||
				dinersPerFrequency === -1 ||
				restaurants.length === 0
			) {
				setCanStartSurvey(false);
				return;
			}
			setCanStartSurvey(true);
		};
		checkStartSurvey();
	}, [surveyTemplateId, frequency, dinersPerFrequency, restaurants]);

	const updateSurveyId = (survey) => {
		setSurveyTemplateId(survey.surveyId);
	};

	const resetValues = () => {
		setRestaurants([]);
		setSurveyTitle('');
		setRewardAmount(0);
		setResetSignal(!resetSignal);
	};

	const handleStartSurveyClick = async () => {
		for (const restaurant of restaurants) {
			try {
				let reqBody = {
					participantCount: dinersPerFrequency,
					frequencyDays: frequency,
					surveyId: surveyTemplateId,
					startDate: surveyStartDate.toDate(),
					surveyTitle: surveyTitle,
					rewardAmount: rewardAmount,
				};
				if (restaurant?.restaurantId) {
					reqBody.restaurantId = restaurant.restaurantId;
				} else {
					reqBody.groupId = restaurant.groupId;
				}
				const response = await axios.post('survey/duplicate-enable', reqBody);
				//TODO setup to receive the array of restaurant names that failed if enabling a group
				if (response.status === 200) {
					resetValues();
					setShowSuccessPopup(true);
				} else {
					setShowFailurePopup(true);
				}
			} catch (err) {
				console.error(err);
			}
		}
	};

	return (
		<div className='flex flex-col py-1 flex-grow min-h-0'>
			<Stack spacing={6} sx={{ pb: 12 }} margin={'2%'}>
				<Box
					display='flex'
					justifyContent='space-between'
					alignItems='stretch'
					flexWrap='wrap'>
					<SelectTemplate
						setSelectedSurvey={updateSurveyId}
						selectedSurvey={surveyTemplateId}
						surveyStartDate={surveyStartDate}
						setSurveyStartDate={setSurveyStartDate}
					/>
					<SelectOptions
						updateFrequency={setFrequency}
						updateParticipants={setDinersPerFrequency}
						rewardAmount={rewardAmount}
						setRewardAmount={setRewardAmount}
						surveyTitle={surveyTitle}
						setSurveyTitle={setSurveyTitle}
					/>
					<SelectRestaurant
						updateRestaurants={setRestaurants}
						resetSignal={resetSignal}
						selectedRestaurants={restaurants}
						setSelectedRestaurants={setRestaurants}
					/>
				</Box>
				<Card
					style={{
						boxShadow:
							'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
					}}>
					<CardContent
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<Stack alignItems='center' spacing={2} sx={{ width: '82%' }}>
							<Typography variant='h6'>Step 4: Start Your Surveys</Typography>
							<Typography variant='subtitle1'>
								When you select to start a survey we will take care of sending
								out invitations to diners in your area based on the frequency
								and number of surveys requested for each of your selected
								restaurants.
							</Typography>
						</Stack>
						<Button
							disabled={!canStartSurvey}
							variant='contained'
							sx={{ minWidth: '150px', maxHeight: '50px' }}
							onClick={handleStartSurveyClick}>
							Start Survey
						</Button>
					</CardContent>
				</Card>
			</Stack>
			<EnabledSurveySuccessPopup
				open={showSuccessPopup}
				onClose={() => setShowSuccessPopup(false)}
			/>
			<EnabledSurveyFailurePopup
				open={showFailurePopup}
				onClose={() => setShowFailurePopup(false)}
			/>
		</div>
	);
};

export default ManageSurveys;
