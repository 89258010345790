import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import {
	IconButton,
	Typography,
	AppBar,
	Box,
	Toolbar,
	Divider,
} from '@mui/material';
import {
	Menu,
	HomeOutlined,
	Description,
	PersonOutline,
	Storefront,
	StackedLineChart,
} from '@mui/icons-material';
import InstallPrompt from '../common/InstallPrompt';
import { useWindowSize } from '@uidotdev/usehooks';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { useAppContext } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';
import PopToast from '../common/PopToast';

const Layout = () => {
	const drawerWidth = 240;
	const [mobileOpen, setMobileOpen] = useState(false);
	const [isClosing, setIsClosing] = useState(false);
	const { user } = useAppContext();
	const location = useLocation();
	useEffect(() => {
		if (location.pathname) {
			switch (location.pathname) {
				case '/':
					setTab(0);
					break;
				case '/survey/invites':
				case '/admin/survey/templates':
					setTab(1);
					break;
				case '/diner/rewards':
				case '/admin/restaurant/manage':
					setTab(2);
					break;
				case '/diner/settings':
				case '/admin/survey/statistics':
					setTab(3);
					break;
				default:
					break;
			}
		}
	}, []);
	const size = useWindowSize();
	const handleDrawerToggle = () => {
		if (!isClosing) {
			setMobileOpen(!mobileOpen);
		}
	};
	const [tab, setTab] = useState(0);
	const navigate = useNavigate();
	const changeTab = (event, newValue) => {
		if (newValue !== tab) {
			setTab(newValue);
			switch (newValue) {
				case 0:
					navigate('/');

					break;
				case 1:
					if (user.admin) {
						navigate('/admin/survey/templates');
					} else {
						navigate('/survey/invites');
					}
					break;
				case 2:
					if (user.admin) {
						navigate('/admin/restaurant/manage');
					} else {
						navigate('/diner/rewards');
					}
					break;
				case 3:
					if (user.admin) {
						//TODO fix later when settings added for manager, for now statistics
						navigate('/admin/survey/statistics');
					} else {
						navigate('/diner/settings');
					}
					break;
				default:
					break;
			}
		}
	};
	return (
		<div className='min-h-[95vh] h-full'>
			<PopToast />
			<div className='h-full bg-white flex flex-col'>

				<AppBar
					position='fixed'
					sx={{
						// width: { sm: `calc(100% - ${drawerWidth}px)` },
						// ml: { sm: `${drawerWidth}px` },
						backgroundColor: 'white',
						minHeight: 0,
					}}
					elevation={0}>
					<Toolbar sx={{ backgroundColor: 'white' }}>
						{(user.admin || user.superAdmin || size.width > 430) && (
							<IconButton
								className='absolute'
								aria-label='open drawer'
								edge='start'
								onClick={handleDrawerToggle}
								sx={{ mr: 2, display: { sm: 'none' } }}>
								<Menu />
							</IconButton>
						)}
						<div className='w-full flex justify-center'>
							<Typography
								variant='h5'
								noWrap
								component='div'
								className='text-[#667085] font-sans font-bold'>
								foodcrit.
							</Typography>
						</div>
					</Toolbar>
					<Divider />
				</AppBar>

				<div className='h-full w-full flex'>
					<Sidebar
						drawerWidth={drawerWidth}
						mobileOpen={mobileOpen}
						setMobileOpen={setMobileOpen}
						setIsClosing={setIsClosing}
					/>
					{/*Consider adding back width: { sm: `calc(100% - ${drawerWidth}px)` } */}
					<div className='w-full h-full flex flex-col min-h-0 flex-grow p-[1.5rem]'>
						<Toolbar />
						<Outlet />
					</div>
				</div>

				<InstallPrompt />
				{size.width <= 430 && (
					<Paper
						sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
						className='z-10'
						elevation={3}>
						<BottomNavigation
							sx={{ height: '64px' }}
							showLabels
							value={tab}
							onChange={changeTab}>
							<BottomNavigationAction label='Home' icon={<HomeOutlined />} />
							<BottomNavigationAction label='Survey' icon={<Description />} />
							{user.admin ? (
								<BottomNavigationAction
									label='Restaurant'
									icon={<Storefront />}
								/>
							) : (
								<BottomNavigationAction label='Rewards' icon={<Storefront />} />
							)}
							{user.admin ? (
								<BottomNavigationAction
									label='Data'
									icon={<StackedLineChart />}
								/>
							) : (
								<BottomNavigationAction
									label='Account'
									icon={<PersonOutline />}
								/>
							)}
						</BottomNavigation>
					</Paper>
				)}
			</div>
		</div>
	);
};

export default Layout;
