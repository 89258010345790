import { Slider, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from '../../../services/auth-header.js';
import { useAppContext } from '../../../context/AppContext.jsx';
import { QuestionResponse } from '../../../models/QuestionResponse.js';
import debounce from '../../../utils/debounce.js';

const SlidingScale = ({ question }) => {
	//TODO update this to use database value, make sure property matches
	const [questionText, setQuestionText] = useState(question.questionText || '');
	const [isCreate, setIsCreate] = useState(true);
	const [questionResponse, setResponse] = useState(
		question.questionResponses?.[0]?.numberResponse || 1
	);

	const { user } = useAppContext();

	const handleQuestionTextChange = async (e) => {
		const newText = e.target.value;
		setQuestionText(newText);
		question.questionText = newText;
		debounce(async () => {
			try {
				let response = await axios.post('question/save', {
					...question,
					newQuestionText: newText,
				});
			} catch (err) {
				console.log(err);
			}
		})();
	};

	const handleResponseChange = async (e) => {
		const newQuestionResponse = e.target.value;
		setResponse(newQuestionResponse);
		debounce(async () => {
			try {
				let response = await axios.post('question/response/save', {
					...question,
					numberResponse: newQuestionResponse,
					dinerId: question.dinerId,
					surveyResponseId: question.surveyResponseId,
					questionResponseId: question.questionReponseId,
				});
			} catch (err) {
				console.error(err);
			}
		})();
	};

	useEffect(() => {
		setIsCreate(user.admin);
	}, []);

	return (
		<Stack alignItems={'center'} spacing={2}>
			{isCreate ? (
				<TextField
					className='question-text'
					value={questionText || ''}
					onChange={handleQuestionTextChange}
					variant='outlined'
					fullWidth
					multiline
					rows={1}
					placeholder={'Enter your question here'}
				/>
			) : (
				<Typography variant='subtitle1' component='legend'>
					{question.questionText}
				</Typography>
			)}
			<Slider
				className='mt-10'
				aria-label='Sliding Scale'
				onChange={handleResponseChange}
				value={questionResponse}
				step={1}
				marks
				valueLabelDisplay={isCreate ? 'auto' : 'on'}
				min={1}
				max={10}
				disabled={isCreate}
			/>
		</Stack>
	);
};

export default SlidingScale;
