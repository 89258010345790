import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import ResetPasswordForm from './ResetPasswordForm.jsx';

const ResetPassword = () => {
	const { token } = useParams();
	//TODO change href to an actual website and make sure copyright is good!!!
	const Copyright = (props) => {
		return (
			<Typography
				variant='body2'
				color='text.secondary'
				align='center'
				{...props}>
				{'Copyright © '}
				<Link color='inherit' href='https://example.com/'>
					Fountain Futures LLC
				</Link>{' '}
				{new Date().getFullYear()}
				{'.'}
			</Typography>
		);
	};

	return (
		<Grid container component='main' sx={{ height: '100vh' }}>
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
					backgroundRepeat: 'no-repeat',
					backgroundColor: (t) =>
						t.palette.mode === 'light'
							? t.palette.grey[50]
							: t.palette.grey[900],
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			/>
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<ResetPasswordForm Copyright={Copyright} token={token} />
			</Grid>
		</Grid>
	);
};

export default ResetPassword;
