import axios from '../../services/auth-header.js';
import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import RewardCard from './RewardCard.jsx';
import { useAppContext } from '../../context/AppContext.jsx';
import Spinner from '../common/spinner/spinner.jsx';
const Rewards = () => {
	const [loading, setLoading] = useState(true);
	const [completedSurveys, setCompletedSurveys] = useState([]);
	const { user } = useAppContext();

	const getCompletedSurveys = async () => {
		setLoading(true);
		try {
			const response = await axios.get(`reward/diner/${user.userId}`);
			setCompletedSurveys(response.data);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getCompletedSurveys();
	}, []);

	return loading ? (
		<Spinner />
	) : (
		<Stack
			alignItems={'center'}
			spacing={2}
			className='max-h-[100vh] overflow-y-scroll py-4 pb-40'>
			{completedSurveys?.length === 0 && (
				<div>You haven't completed any surveys yet.</div>
			)}
			{completedSurveys?.length > 0 &&
				completedSurveys.map((survey) => {
					return (
						<RewardCard
							key={survey.surveyInvitationId}
							survey={survey}></RewardCard>
					);
				})}
		</Stack>
	);
};

export default Rewards;
