import {
	Button,
	Card,
	CardContent,
	Container,
	Stack,
	Avatar,
	Typography,
} from '@mui/material';
import axios from '../../../services/auth-header.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DescriptionOutlined } from '@mui/icons-material';

const SurveyCard = ({ survey }) => {
	const [restaurant, setRestaurant] = useState(null);
	const [logoPath, setLogoPath] = useState('');
	const navigate = useNavigate();

	const daysLeft = () =>
		Math.ceil(
			(new Date(survey.expirationDate).getTime() - new Date().getTime()) /
				(1000 * 3600 * 24)
		);
	const getRestaurant = async () => {
		try {
			const result = await axios.get(`restaurant/${survey.restaurantId}`);
			const restaurantResponse = result.data;
			if (!restaurantResponse) {
				setRestaurant(null);
			} else {
				setRestaurant(result.data);
				setLogoPath(
					process.env.REACT_APP_S3_BUCKET_URL + restaurantResponse.logoPath
				);
			}
		} catch (error) {
			console.error('ERROR FETCHING RESTAURANT:\n' + error);
		}
	};

	useEffect(() => {
		getRestaurant();
	}, []);

	return (
		<Card
			variant='outlined'
			className='w-full overflow-visible'
			style={{ maxWidth: '32rem' }}>
			<CardContent>
				<Container>
					<Stack alignItems={'center'} spacing={2}>
						{restaurant && (
							<Avatar
								alt={`${restaurant.restaurantName} Logo`}
								src={process.env.REACT_APP_S3_BUCKET_URL + restaurant?.logoPath}
								sx={{ width: 80, height: 80 }}
							/>
						)}
						{/* <div className='font-bold'>
							{survey ? survey.surveyTitle : 'NO TITLE'}
						</div> */}
						<div className='font-bold'>
							{restaurant && restaurant.restaurantName}
						</div>
						<div className='italic'>
							{restaurant &&
								`${restaurant.city}, ${restaurant.stateAbbreviation}`}
						</div>
						<Button
							className='px-4 py-2 w-full flex space-x-4'
							variant='outlined'
							sx={{ color: 'black' }}
							onClick={() => navigate('/survey/respond')}
							startIcon={<DescriptionOutlined />}>
							<Typography variant='p' noWrap>
								Take survey now
							</Typography>
							<Typography
								variant='p'
								noWrap
								sx={{ fontSize: '0.5rem', color: '#0D9A0A' }}>
								{daysLeft()} days left
							</Typography>
						</Button>
					</Stack>
				</Container>
			</CardContent>
		</Card>
	);
};

export default SurveyCard;
