import axios from '../../../services/auth-header.js';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../context/AppContext.jsx';
import Spinner from '../../common/spinner/spinner.jsx';
import { Stack, Typography, Button } from '@mui/material';
import InviteCard from './InviteCard.jsx';
import Sheet from 'react-modal-sheet';
import { useNavigate } from 'react-router-dom';
import { NotificationsActiveOutlined } from '@mui/icons-material';
import SurveyCard from './SurveyCard.jsx';
const SurveyInvitations = () => {
	const [loading, setLoading] = useState(true);
	const [invites, setInvites] = useState(null);
	const [selectedInvite, setSelectedInvite] = useState(null);
	const [currentSurvey, setCurrentSurvey] = useState(null);
	const { user } = useAppContext();
	const [open, setOpen] = useState(false);

	const getActiveSurvey = async () => {
		try {
			const result = await axios.get(`survey/response/active/${user.userId}`);
			const surveyResponse = result.data[0];
			if (surveyResponse) {
				setCurrentSurvey(surveyResponse);
			}
		} catch (error) {
			console.error('ERROR FETCHING SURVEY:\n' + error);
		}
	};

	useEffect(() => {
		if (!user.admin) {
			getActiveSurvey();
		}
	}, []);

	const getInvitations = async () => {
		setLoading(true);
		try {
			const response = await axios.get(`survey/invite/diner/${user.userId}`);
			setInvites(response.data);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};
	const openDenyModal = (invitationId) => {
		setOpen(true);
		setSelectedInvite(invitationId);
	};
	const decline = async () => {
		try {
			const result = await axios.post(
				`/survey/invite/decline/${selectedInvite}`
			);
			if (result) {
				getInvitations();
			}
		} catch (error) {
			console.log('ERROR DECLINING SURVEY:\n' + error);
		}
		setOpen(false);
		setSelectedInvite(null);
	};

	useEffect(() => {
		getInvitations();
	}, []);

	const BottomModal = () => (
		<Sheet
			snapPoints={[0.5]}
			initialSnap={0}
			isOpen={open}
			onClose={() => setOpen(false)}>
			<Sheet.Container className=' rounded-md'>
				<Sheet.Header />
				<Sheet.Content>
					<Stack alignItems={'center'} spacing={2} sx={{ py: 2, px: 8 }}>
						<div className='rotate-[25deg]'>
							<NotificationsActiveOutlined
								sx={{ fontSize: '6rem' }}
								fontSize='inherit'
							/>
						</div>
						<Typography
							variant='h5'
							component='h5'
							className='font-bold text-center'>
							Are you sure you want to deny this survey?
						</Typography>
						<Typography variant='p' component='p' className='text-center'>
							Denying survey too often will result you being deprioritized of
							receiving survey in the future.
						</Typography>
						<Button
							variant='contained'
							color='error'
							sx={{ fontWeight: '600', py: '0.5rem', px: '2rem' }}
							onClick={decline}>
							Yes, I deny this survey.
						</Button>
						<Button
							onClick={() => setOpen(false)}
							variant='text'
							sx={{ color: 'black' }}>
							Let me think about it again
						</Button>
					</Stack>
				</Sheet.Content>
			</Sheet.Container>
			<Sheet.Backdrop />
		</Sheet>
	);

	return loading ? (
		<Spinner />
	) : (
		<Stack
			alignItems={'center'}
			spacing={2}
			className='max-h-[100vh] overflow-y-scroll pb-40'>
			<Typography className='self-start font-bold' variant='h4'>
				Surveys
			</Typography>
			{!invites && !currentSurvey && <div>You don't have any invitations</div>}
			{currentSurvey && <SurveyCard survey={currentSurvey} />}
			{!currentSurvey && invites && (
				<Typography variant='xmd' className='self-start text-[#444444]'>
					Only one survey will be assigned to you at a time, please review the
					details of the survey and make your decision timely, someone else is
					always trying to accept the survey while you are considering.
				</Typography>
			)}
			{!currentSurvey &&
				invites &&
				invites.map((invitation) => {
					return (
						<InviteCard
							key={invitation.surveyInvitationId}
							invitation={invitation}
							openDenyModal={openDenyModal}></InviteCard>
					);
				})}
			<BottomModal />
		</Stack>
	);
};

export default SurveyInvitations;
