import { Stack, Card, CardContent, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { QuestionType } from '../../common/enums.js';

const QuestionResponse = ({ questionResponse }) => {
	const [response, setResponse] = useState('');
	const [isImage, setIsImage] = useState(false);
	useEffect(() => {
		const type = questionResponse.question.questionType;
		setResponse(getQuestionComponent(type));
	}, []);

	const getQuestionComponent = (type) => {
		var formattedResponse;
		const unanswered = 'No Response';
		switch (type) {
			case QuestionType.FIVE_STAR:
				formattedResponse =
					questionResponse.numberResponse !== null
						? questionResponse.numberResponse + ' star(s) out of 5'
						: unanswered;
				return formattedResponse;

			case QuestionType.SHORT_TEXT:
				formattedResponse =
					questionResponse.textReponseShort !== null
						? questionResponse.textResponseShort
						: unanswered;
				return formattedResponse;

			case QuestionType.LONG_TEXT:
				formattedResponse =
					questionResponse.textReponseLong !== null
						? questionResponse.textResponseLong
						: unanswered;
				return formattedResponse;

			case QuestionType.MULTIPLE_CHOICE:
				const choice =
					questionResponse.numberResponse !== null
						? questionResponse.question.questionChoices[
								questionResponse.numberResponse
						  ].choiceText
						: unanswered;
				if (choice !== unanswered) {
					formattedResponse = 'Diner selected "' + choice + '"';
				} else {
					formattedResponse = unanswered;
				}
				return formattedResponse;

			case QuestionType.SLIDING_SCALE:
				formattedResponse =
					questionResponse.numberResponse !== null
						? questionResponse.numberResponse + ' out of 10.'
						: unanswered;
				return formattedResponse;

			case QuestionType.YES_NO:
				formattedResponse =
					questionResponse.numberResponse !== null
						? questionResponse.numberResponse === 1
							? 'Yes'
							: 'No'
						: unanswered;
				return formattedResponse;

			case QuestionType.TIMER:
				const seconds =
					questionResponse.numberResponse !== null
						? questionResponse.numberResponse
						: unanswered;

				formattedResponse = convertSecondsToTime(seconds);
				return formattedResponse;

			case QuestionType.PICTURE:
				const imagePath =
					questionResponse.imagePath !== null
						? process.env.REACT_APP_S3_BUCKET_URL + questionResponse.imagePath
						: null;

				if (imagePath) {
					setIsImage(true);
					return imagePath;
				} else {
					return unanswered;
				}

			default:
				return 'Question response not recognized';
		}
	};

	function convertSecondsToTime(milliseconds) {
		const seconds = milliseconds / 1000;
		let hours = Math.floor(seconds / 3600);
		let remainingSeconds = seconds % 3600;
		let minutes = Math.floor(remainingSeconds / 60);
		remainingSeconds = remainingSeconds % 60;

		let timeString = '';
		if (hours > 0) {
			timeString += hours + ' hour';
			if (hours > 1) timeString += 's';
			timeString += ' ';
		}
		if (minutes > 0) {
			timeString += minutes + ' minute';
			if (minutes > 1) timeString += 's';
			timeString += ' ';
		}
		if (seconds > 0 || (hours === 0 && minutes === 0)) {
			timeString += remainingSeconds + ' second';
			if (remainingSeconds !== 1) timeString += 's';
		}

		return timeString;
	}

	function downloadImage(imagePath) {
		const link = document.createElement('a');
		link.href = imagePath;
		link.download =
			questionResponse.question.questionText + '_' + questionResponse.dinerId;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	return (
		<Card
			style={{
				width: '80%',
				cursor: 'pointer',
				// backgroundColor:
				// 	selectedResponse === response ? '#f0f0f0' : 'white',
				boxShadow:
					'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
				marginLeft: '10%',
				marginRight: '10%',
			}}
			variant='outlined'>
			<CardContent>
				<Stack
					direction='column'
					spacing={2}
					alignItems='center'
					justifyContent='center'>
					<Typography variant='h6'>
						<b>{questionResponse.question.questionText}</b>
					</Typography>
					{isImage ? (
						<img
							src={response}
							alt=''
							style={{ maxWidth: '100%', maxHeight: '200px' }}
							onClick={() => downloadImage(response)}
						/>
					) : (
						<Typography variant='h6'>{response}</Typography>
					)}
				</Stack>
			</CardContent>
		</Card>
	);
};

export default QuestionResponse;
