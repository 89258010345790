import {
	Card,
	CardContent,
	Typography,
	Stack,
	Box,
	Paper,
} from '@mui/material';
import { useEffect, useState } from 'react';
import axios from '../../services/auth-header.js';
import UneditableSurvey from '../survey/manage/survey/UneditableSurvey.jsx';
import { useAppContext } from '../../context/AppContext.jsx';
const OperatorTools = () => {
	const [responses, setResponses] = useState([]);
	const [selectedResponse, setSelectedResponse] = useState(null);
	const { openToast } = useAppContext();
	const approveSurvey = async () => {
		try {
			const response = await axios.post('reward/send', {
				surveyResponseId: selectedResponse.surveyResponseId,
			});
			if (response.data) {
				getSurveyData();
			} else {
				openToast(
					'An error occurred while approving survey response, please try again later.',
					'error'
				);
			}
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		getSurveyData();
	}, []);

	const getSurveyData = async () => {
		try {
			const response = await axios.get(`reward`);
			if (response.data) {
				setResponses(response.data);
				setSelectedResponse(response.data[0]);
			} else {
				setResponses([]);
			}
		} catch (error) {
			console.error('Error fetching survey data:', error);
		}
	};

	return (
		<Stack
			direction='row'
			spacing={2}
			style={{ height: '100%', flexGrow: 1, margin: '20px' }}>
			{responses.length > 0 ? (
				<Stack
					flexGrow={1}
					spacing={2}
					style={{ maxHeight: '90%', overflowY: 'auto', flexGrow: 1 }}>
					{' '}
					<Paper elevation={2}>
						<Stack spacing={2}>
							{responses.map((response, index) => (
								<Card
									variant='outlined'
									key={index}
									onClick={() => setSelectedResponse(response)}
									style={{
										// margin: '10px',
										cursor: 'pointer',
										backgroundColor:
											selectedResponse === response ? '#f0f0f0' : 'white',
										boxShadow:
											'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
										transition: 'background-color 0.3s ease',
									}}>
									<CardContent>
										<Typography variant='h5'>
											{/* <b>Response {index + 1}</b> */}
										</Typography>
										<Typography>
											<b>Restaurant Location:</b>{' '}
											{response?.restaurant?.restaurantName} -{' '}
											{response?.restaurant?.city}
											<br />
											<b>Survey Title:</b> {response.survey?.surveyTitle}
											<br />
											<b>Survey Description:</b> {response.survey?.surveyIntro}
											<br />
										</Typography>
									</CardContent>
								</Card>
							))}
						</Stack>
					</Paper>
				</Stack>
			) : (
				<p>No responses available</p>
			)}
			{selectedResponse && (
				<Paper
					elevation={2}
					style={{
						maxHeight: '90%',
						overflowY: 'auto',
						flexGrow: 2,
						// margin: '10px',
						width: '80%',
					}}>
					<Stack spacing={2}>
						<Card
							style={{
								width: '80%',
								cursor: 'pointer',
								// backgroundColor:
								// 	selectedResponse === response ? '#f0f0f0' : 'white',
								boxShadow:
									'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
								marginLeft: '10%',
								marginRight: '10%',
								marginTop: '5%',
							}}>
							<CardContent>
								<Stack
									direction='row'
									spacing={2}
									alignContent={'center'}
									justifyContent={'center'}>
									<Typography variant='h6' flexGrow={1} align='center'>
										<b>Restaurant Location:</b>{' '}
										{selectedResponse?.restaurant?.restaurantName} -{' '}
										{selectedResponse?.restaurant?.city}
										<br />
										<b>Survey Title:</b> {selectedResponse.survey?.surveyTitle}
										<br />
										<b>Survey Description:</b>{' '}
										{selectedResponse.survey?.surveyIntro}
										<br />
									</Typography>
								</Stack>
							</CardContent>
						</Card>

						<UneditableSurvey
							approveSurvey={approveSurvey}
							surveyResponse={selectedResponse}
						/>
					</Stack>
				</Paper>
			)}
		</Stack>
	);
};

export default OperatorTools;
