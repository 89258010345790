import { Rating, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../context/AppContext';
import axios from '../../../services/auth-header.js';
import debounce from '../../../utils/debounce.js';

const FiveStarRating = ({ question }) => {
	const [isCreate, setIsCreate] = useState(true);
	const { user } = useAppContext();
	const [questionText, setQuestionText] = useState(question.questionText || '');
	const [response, setResponse] = useState(
		question.questionResponses?.[0]?.numberResponse || 0
	);

	const handleQuestionTextChange = async (e) => {
		const newText = e.target.value;
		setQuestionText(newText);
		question.questionText = newText;
		debounce(async () => {
			try {
				let response = await axios.post('question/save', {
					...question,
					newQuestionText: newText,
				});
			} catch (err) {
				console.log(err);
			}
		})();
	};

	const handleResponseChange = async (e, newVal) => {
		setResponse(newVal);
		try {
			await axios.post('question/response/save', {
				...question,
				numberResponse: newVal,
				dinerId: question.dinerId,
				surveyResponseId: question.surveyResponseId,
				questionResponseId: question.questionResponseId,
			});
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		setIsCreate(user.admin);
	}, []);

	return (
		<Stack alignItems={'center'} spacing={2}>
			{isCreate ? (
				<TextField
					className='question-text'
					value={questionText}
					onChange={handleQuestionTextChange}
					variant='outlined'
					fullWidth
					multiline
					rows={1}
					placeholder={'Enter your question here'}
				/>
			) : (
				<Typography variant='subtitle1' component='legend'>
					{question.questionText}
				</Typography>
			)}
			<Rating
				className='rating-star'
				value={response}
				onChange={handleResponseChange}
				size='large'
				disabled={isCreate}
			/>
		</Stack>
	);
};

export default FiveStarRating;
