import FiveStarRating from './questions/FiveStarRating';
import ShortTextResponse from './questions/ShortTextResponse';
import LongTextResponse from './questions/LongTextResponse';
import MultipleChoice from './questions/MultipleChoice';
import QuestionCard from './questions/QuestionCard';
import axios from '../../services/auth-header.js';
import { useEffect, useState } from 'react';
import Spinner from '../common/spinner/spinner.jsx';
import { Stack } from '@mui/material';
import SlidingScale from './questions/SlidingScale.jsx';

const SurveyTest = () => {
	const [loading, setLoading] = useState(true);
	const [survey, setSurvey] = useState(null);
	const getTestSurvey = async () => {
		try {
			const response = await axios.get('/survey/-1');
			setSurvey(response.data);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getTestSurvey();
	}, []);
	const getQuestionComponent = (type) => {
		switch (type) {
			case 'FIVE_STAR':
				return FiveStarRating;
			case 'SHORT_TEXT':
				return ShortTextResponse;
			case 'LONG_TEXT':
				return LongTextResponse;
			case 'SHORT_TEXT':
				return ShortTextResponse;
			case 'MULTIPLE_CHOICE':
				return MultipleChoice;
			case 'SLIDING_SCALE':
				return SlidingScale;
			default:
				return FiveStarRating;
		}
	};
	return (
		<Stack
			alignItems={'center'}
			spacing={2}
			className='max-h-[100vh] overflow-y-scroll py-4'>
			{loading ? (
				<Spinner />
			) : (
				survey.questions.map((question) => {
					const QuestionComponent = getQuestionComponent(question.questionType);
					return (
						<QuestionCard key={question.questionId} question={question}>
							<QuestionComponent question={question} />
						</QuestionCard>
					);
				})
			)}
		</Stack>
	);
};

export default SurveyTest;
