import { Card, CardContent, Container, Stack, Avatar } from '@mui/material';

const RewardCard = ({ survey }) => {
	return (
		<Card
			variant='outlined'
			className='w-full overflow-visible'
			style={{ maxWidth: '32rem' }}>
			<CardContent>
				<Container>
					<Stack alignItems={'center'} spacing={1}>
						{survey?.restaurant && (
							<Avatar
								alt={`${survey.restaurant.restaurantName} Logo`}
								src={
									process.env.REACT_APP_S3_BUCKET_URL +
									survey.restaurant?.logoPath
								}
								sx={{ width: 80, height: 80 }}
							/>
						)}
						<div className='font-bold'>
							{survey?.survey?.surveyTitle || 'NO TITLE'}
						</div>
						<div className='font-bold'>
							{survey?.restaurant && survey.restaurant.restaurantName}
						</div>
						<div className='italic'>
							{survey?.restaurant &&
								`${survey.restaurant.city}, ${survey.restaurant.stateAbbreviation}`}
						</div>
						<div className='font-bold'>{`Amount: $${survey.survey?.rewardAmount}`}</div>
						<div className='font-bold'>
							{`Status: ${
								survey?.rewarded ? 'Reward Sent' : 'Reward Processing'
							}`}
						</div>
					</Stack>
				</Container>
			</CardContent>
		</Card>
	);
};

export default RewardCard;
