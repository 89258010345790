import React, { useState, useEffect } from 'react';
import {
	Stack,
	Typography,
	TextField,
	IconButton,
	InputAdornment,
	Button,
	Box,
	Collapse,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Checkbox,
	Card,
	CardContent,
	Paper,
	MenuItem,
	Menu,
} from '@mui/material';
import {
	Search as SearchIcon,
	Add as AddIcon,
	ArrowDropDown as ArrowDropDownIcon,
	ArrowRight as ArrowRightIcon,
	Edit as EditIcon,
	MoreVert as MoreVertIcon,
	GroupWork as GroupWorkIcon,
	Delete,
} from '@mui/icons-material';
import UpsertGroupModal from './UpsertGroupModal.jsx';
import { useAppContext } from '../../../../context/AppContext';
import axios from '../../../../services/auth-header.js';
import Spinner from '../../../common/spinner/spinner.jsx';
import GroupRestaurants from './GroupRestaurants.jsx';

const ManageGroups = () => {
	const { user } = useAppContext();
	const [groups, setGroups] = useState([]);
	const [allRestaurants, setAllRestaurants] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedRestaurants, setSelectedRestaurants] = useState([]);
	const [upsertGroupModalOpen, setUpsertGroupModalOpen] = useState(false);
	const [openGroup, setOpenGroup] = useState(-1);
	const [openRestaurant, setOpenRestaurant] = useState(null);
	const [editGroup, setEditGroup] = useState(null);
	const [loading, setLoading] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);

	const openGroupMenu = (e, restaurantId) => {
		setAnchorEl(e.currentTarget);
		setOpenRestaurant(restaurantId);
	};
	const closeGroupMenu = () => {
		setAnchorEl(null);
		setOpenRestaurant(null);
	};
	const editGroupName = (group) => {
		setEditGroup(group);
		setUpsertGroupModalOpen(true);
	};

	const handleExpandCollapse = (groupId) => {
		if (openGroup === groupId) {
			setOpenGroup(null);
		} else {
			setOpenGroup(groupId);
		}
	};

	const refreshGroups = async () => {
		if (user.userId) {
			try {
				const groupResponse = await axios.get(`group/${user.userId}`);
				if (groupResponse.data) {
					setGroups(groupResponse.data);
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	const getRestaurants = async () => {
		try {
			const response = await axios.get(`restaurant/manager/${user.userId}`);
			if (response.data) {
				setAllRestaurants(response.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const refreshData = async () => {
		setLoading(true);
		await refreshGroups();
		await getRestaurants();
		setLoading(false);
	};

	useEffect(() => {
		refreshData();
	}, []);

	const handleSelectRestaurant = (restaurantId) => {
		if (selectedRestaurants.includes(restaurantId)) {
			setSelectedRestaurants(
				selectedRestaurants.filter((id) => id !== restaurantId)
			);
		} else {
			setSelectedRestaurants([...selectedRestaurants, restaurantId]);
		}
	};

	const handleSelectAllInGroup = (group) => {
		let restaurantIds = [];
		if (group === -1) {
			restaurantIds = allRestaurants
				?.filter(filterBySearch)
				.map((restaurant) => restaurant.restaurantId);
		} else {
			restaurantIds = group.restaurants
				?.filter(filterBySearch)
				.map((restaurant) => restaurant.restaurantId);
		}
		const allSelected = restaurantIds.every((id) =>
			selectedRestaurants.includes(id)
		);
		if (allSelected) {
			// Unselect all in group
			setSelectedRestaurants(
				selectedRestaurants.filter((id) => !restaurantIds.includes(id))
			);
		} else {
			// Select all in group
			setSelectedRestaurants([
				...new Set([...selectedRestaurants, ...restaurantIds]),
			]);
		}
	};

	const isAllSelectedInGroup = (group) => {
		let restaurantIds = [];
		if (group === -1) {
			restaurantIds = allRestaurants
				?.filter(filterBySearch)
				.map((restaurant) => restaurant.restaurantId);
		} else {
			if (group?.restaurants?.length === 0) {
				return false;
			}
			restaurantIds = group.restaurants
				?.filter(filterBySearch)
				.map((restaurant) => restaurant.restaurantId);
		}

		return restaurantIds.every((id) => selectedRestaurants.includes(id));
	};

	const filterBySearch = (restaurant) => {
		return (
			searchTerm === '' ||
			restaurant.restaurantName
				?.toLowerCase()
				.includes(searchTerm?.toLowerCase())
		);
	};
	const addRestaurantsToGroup = async (groupId, restaurantId) => {
		try {
			const response = await axios.post('group/restaurant/add', {
				groupId: groupId,
				restaurantIds: restaurantId ? [restaurantId] : selectedRestaurants,
			});
			refreshData();
			setSelectedRestaurants([]);
			closeGroupMenu();
		} catch (error) {
			console.error(error);
		}
	};
	const removeRestaurantsFromGroup = async (restaurantIds, groupId) => {
		try {
			const response = await axios.post('group/restaurant/remove', {
				groupId: groupId || openGroup,
				restaurantIds: restaurantIds,
			});
			refreshData();
			setSelectedRestaurants([]);
		} catch (error) {
			console.error(error);
		} finally {
			closeGroupMenu();
		}
	};
	useEffect(() => {
		if (!upsertGroupModalOpen) {
			setEditGroup(null);
		}
	}, [upsertGroupModalOpen]);

	useEffect(() => {
		setSelectedRestaurants([]);
	}, [openGroup]);

	const tableCellSx = {
		color: '#718EBF',
		fontSize: '12px',
		fontWeight: 500,
	};
	const GroupTableHead = () => {
		return (
			<TableHead>
				<TableRow>
					<TableCell padding='checkbox'>
						<Checkbox
							size='small'
							checked={isAllSelectedInGroup(-1)}
							onChange={() => handleSelectAllInGroup(-1)}
						/>
					</TableCell>
					<TableCell sx={tableCellSx}>Restaurant</TableCell>
					<TableCell sx={tableCellSx}>Address</TableCell>
					<TableCell sx={tableCellSx}>Phone Number</TableCell>
					<TableCell sx={tableCellSx}>Manager</TableCell>
					<TableCell sx={tableCellSx}>Group</TableCell>
				</TableRow>
			</TableHead>
		);
	};
	return loading ? (
		<Spinner />
	) : (
		<div className='flex flex-col py-1 flex-grow min-h-0'>
			{upsertGroupModalOpen && (
				<UpsertGroupModal
					open={upsertGroupModalOpen}
					onClose={() => setUpsertGroupModalOpen(false)}
					existingGroup={editGroup}
					refreshGroups={refreshData}
				/>
			)}
			<Typography variant='h4' sx={{ marginBottom: '16px' }}>
				Manage Groups
			</Typography>

			{/* Search Field */}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					backgroundColor: '#FFFFFF',
					padding: '8px',
					marginBottom: '16px',
				}}>
				<TextField
					variant='standard'
					placeholder='Search restaurant'
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					fullWidth
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<SearchIcon sx={{ fontSize: 24 }} />
							</InputAdornment>
						),
						disableUnderline: true,
					}}
				/>
			</Box>

			{/* Content Section */}
			<Paper
				square={false}
				elevation={3}
				sx={{
					flexGrow: 1,
					backgroundColor: '#F5F7FA',
					p: '16px',
					mb: 4,
				}}>
				{/* This is where to add the max height, has flex overflow issue */}
				<Stack direction='row' spacing={2} sx={{ marginBottom: '16px' }}>
					<Button
						variant='text'
						startIcon={<AddIcon sx={{ fontSize: 20 }} />} // Softer blue
						color='primary'
						sx={{ fontSize: '12px', textTransform: 'none' }}
						onClick={() => {
							setUpsertGroupModalOpen(true);
						}}>
						Create a new group
					</Button>
					<GroupRestaurants
						disabled={selectedRestaurants?.length === 0}
						groups={groups}
						handleSubmit={addRestaurantsToGroup}
					/>
				</Stack>
				<Stack>
					<Box key={-1} sx={{ marginBottom: '8px' }}>
						{/* Group Header */}
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								padding: '8px',
								cursor: 'pointer',
							}}
							onClick={() => handleExpandCollapse(-1)}>
							{openGroup === -1 ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
							<Typography
								variant='body1'
								sx={{ fontSize: '14px', fontWeight: 500 }}>
								All Restaurants
							</Typography>
							<Typography
								variant='body2'
								sx={{
									marginLeft: '8px', // Adjusted to align next to the edit icon
									color: '#686868',
									fontSize: '12px',
								}}>
								{`${allRestaurants?.filter(filterBySearch).length || 0} ${
									(allRestaurants?.filter(filterBySearch).length || 0) === 1
										? 'store'
										: 'stores'
								}`}
							</Typography>
						</Box>

						{/* Restaurants Table */}
						<Collapse in={openGroup === -1} timeout='auto' unmountOnExit>
							<Box
								sx={{
									backgroundColor: '#FFFFFF', // White background
									borderRadius: '8px', // Slightly rounded corners
									padding: '16px', // Added padding for a cleaner look
								}}>
								<Table>
									<GroupTableHead />
									<TableBody>
										{allRestaurants
											?.filter(filterBySearch)
											.map((restaurant) => (
												<TableRow
													key={restaurant.restaurantId}
													sx={{
														height: '50px',
														borderBottom: '1px solid #D3D3D3',
													}}>
													<TableCell padding='checkbox'>
														<Checkbox
															size='small'
															checked={selectedRestaurants.includes(
																restaurant.restaurantId
															)}
															onChange={() =>
																handleSelectRestaurant(restaurant.restaurantId)
															}
														/>
													</TableCell>
													<TableCell sx={{ fontSize: '12px' }}>
														<Box sx={{ display: 'flex', alignItems: 'center' }}>
															{restaurant.restaurantName}
															<IconButton
																size='small'
																sx={{ marginLeft: '8px' }}
																onClick={(e) =>
																	openGroupMenu(e, restaurant.restaurantId)
																}>
																<MoreVertIcon sx={{ fontSize: 20 }} />
															</IconButton>
															<Menu
																id='restaurant-menu'
																anchorEl={anchorEl}
																open={!!anchorEl}
																onClose={closeGroupMenu}>
																<GroupRestaurants
																	disabled={false}
																	groups={groups}
																	handleSubmit={addRestaurantsToGroup}
																	restaurant={openRestaurant}
																	variant='text'
																/>
															</Menu>
														</Box>
													</TableCell>
													<TableCell sx={{ fontSize: '12px' }}>
														{`${restaurant?.streetAddress}, ${restaurant?.city}, ${restaurant?.stateAbbreviation}`}
													</TableCell>
													<TableCell sx={{ fontSize: '12px' }}>
														{restaurant.phone}
													</TableCell>
													<TableCell sx={{ fontSize: '12px' }}>
														{restaurant.manager}
													</TableCell>
													<TableCell sx={{ fontSize: '12px' }}>
														{'input groups here'}
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</Box>
					{/* Groups */}
					{groups.map((group) => {
						const restaurantCount =
							group?.restaurants?.filter(filterBySearch).length || 0;
						return (
							<Box key={group.groupId} sx={{ marginBottom: '8px' }}>
								{/* Group Header */}
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										padding: '8px',
										backgroundColor: '#F5F7FA', // Same background color as "All Restaurants"
										cursor: 'pointer',
									}}
									onClick={() => handleExpandCollapse(group.groupId)}>
									{openGroup === group.groupId ? (
										<ArrowDropDownIcon />
									) : (
										<ArrowRightIcon />
									)}
									<Typography
										variant='body1'
										sx={{ fontSize: '14px', fontWeight: 500 }}>
										{group.groupName}
									</Typography>
									<IconButton
										size='small'
										sx={{ marginLeft: '8px' }}
										onClick={(e) => {
											e.stopPropagation();
											editGroupName(group);
										}}>
										<EditIcon sx={{ fontSize: 14 }} />
									</IconButton>

									<Typography
										variant='body2'
										sx={{
											marginLeft: '8px', // Store count right next to the edit icon
											color: '#686868',
											fontSize: '12px',
										}}>
										{`${restaurantCount} ${
											restaurantCount === 1 ? 'store' : 'stores'
										}`}
									</Typography>
									{openGroup === group.groupId && (
										<Button
											variant='text'
											startIcon={
												<Delete sx={{ color: 'error', fontSize: 14 }} />
											}
											sx={{
												fontSize: '12px',
												textTransform: 'none',
												ml: '1rem',
											}}
											color='error'
											onClick={(e) => {
												e.stopPropagation();
												removeRestaurantsFromGroup(
													selectedRestaurants,
													group.groupId
												);
											}}
											disabled={selectedRestaurants?.length === 0}>
											Remove selected restaurants
										</Button>
									)}
								</Box>

								{/* Restaurants Table */}
								<Collapse
									in={openGroup === group.groupId}
									timeout='auto'
									unmountOnExit>
									<Box
										sx={{
											backgroundColor: '#FFFFFF', // White background for all groups' tables
											borderRadius: '8px', // Rounded corners for the table view
											padding: '16px', // Padding for a cleaner look
										}}>
										<Table>
											<GroupTableHead />
											<TableBody>
												{group?.restaurants
													?.filter(filterBySearch)
													.map((restaurant) => (
														<TableRow
															key={restaurant.restaurantId}
															sx={{
																height: '50px',
																borderBottom: '1px solid #D3D3D3',
															}}>
															<TableCell padding='checkbox'>
																<Checkbox
																	size='small'
																	checked={selectedRestaurants.includes(
																		restaurant.restaurantId
																	)}
																	onChange={() =>
																		handleSelectRestaurant(
																			restaurant.restaurantId
																		)
																	}
																/>
															</TableCell>
															<TableCell sx={{ fontSize: '12px' }}>
																<Box
																	sx={{
																		display: 'flex',
																		alignItems: 'center',
																	}}>
																	{restaurant.restaurantName}
																	<IconButton
																		size='small'
																		sx={{ marginLeft: '8px' }}
																		onClick={(e) =>
																			openGroupMenu(e, restaurant.restaurantId)
																		}>
																		<MoreVertIcon sx={{ fontSize: 20 }} />
																	</IconButton>
																	<Menu
																		id='restaurant-menu'
																		anchorEl={anchorEl}
																		open={!!anchorEl}
																		onClose={closeGroupMenu}>
																		<GroupRestaurants
																			disabled={false}
																			groups={groups}
																			handleSubmit={addRestaurantsToGroup}
																			restaurant={openRestaurant}
																			variant='text'
																		/>
																		<MenuItem
																			sx={{ fontSize: 12 }}
																			onClick={() =>
																				removeRestaurantsFromGroup([
																					openRestaurant,
																				])
																			}>
																			Remove From Group
																		</MenuItem>
																	</Menu>
																</Box>
															</TableCell>
															<TableCell sx={{ fontSize: '12px' }}>
																{`${restaurant?.streetAddress}, ${restaurant?.city}, ${restaurant?.stateAbbreviation}`}
															</TableCell>
															<TableCell sx={{ fontSize: '12px' }}>
																{restaurant.phone}
															</TableCell>
															<TableCell sx={{ fontSize: '12px' }}>
																{restaurant.manager}
															</TableCell>
															<TableCell sx={{ fontSize: '12px' }}>
																{group.groupName}
															</TableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>
									</Box>
								</Collapse>
							</Box>
						);
					})}
				</Stack>
			</Paper>
		</div>
	);
};

export default ManageGroups;
