import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	typography: {
		fontFamily: 'Poppins, Arial, sans-serif',
		sm: {
			fontWeight: 400,
			fontSize: '0.625rem',
			lineHeight: '0.938rem',
		},
		md: {
			fontWeight: 400,
			fontSize: '0.75rem',
			lineHeight: '1.375rem',
		},
		hsm: {
			fontWeight: 400,
			fontSize: '0.625rem',
			lineHeight: '1.375rem',
		},
		smTitle: {
			fontWeight: 600,
			fontSize: '1rem',
			lineHeight: '1.375rem',
		},
		xmd: {
			fontWeight: 400,
			fontSize: '0.875rem',
			lineHeight: '1.375rem',
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          src: url('https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfedw.woff2') format('woff2');
        }
        body {
          font-family: 'Poppins, Arial, sans-serif';
        }
      `,
		},
	},
});

export default theme;
