import { useEffect } from 'react';
import { useState } from 'react';
import {
	Card,
	CardContent,
	Typography,
	Stack,
	TextField,
	Autocomplete,
	InputAdornment,
} from '@mui/material';

const SelectOptions = ({
	updateFrequency,
	updateParticipants,
	rewardAmount,
	setRewardAmount,
	surveyTitle,
	setSurveyTitle,
}) => {
	const [frequency, setFrequency] = useState('');
	const [participants, setParticipants] = useState('');
	const frequencyOptions = [
		{ label: 'Weekly', days: 7 },
		{ label: 'Bi-Weekly', days: 14 },
		{ label: 'Monthly', days: 30 },
	];

	const participantOptions = [
		{ label: '1 Participant', count: 1 },
		{ label: '2 Participants', count: 2 },
		{ label: '3 Participants', count: 3 },
		{ label: '4 Participants', count: 4 },
		{ label: '5 Participants', count: 5 },
		{ label: '6 Participants', count: 6 },
		{ label: '7 Participants', count: 7 },
		{ label: '8 Participants', count: 8 },
		{ label: '9 Participants', count: 9 },
		{ label: '10 Participants', count: 10 },
	];

	const handleFrequencyChange = (event, newValue) => {
		setFrequency(newValue.label);
		updateFrequency(newValue.days);
	};

	const handleParticipantChange = (event, newValue) => {
		setParticipants(newValue.label);
		updateParticipants(newValue.count);
	};

	return (
		<Card
			sx={{
				flex: '1 1 auto',
				maxWidth: '30%',
				minHeight: '100%',
				boxShadow:
					'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
			}}>
			<CardContent align='center'>
				<Stack spacing={4}>
					<Typography variant='h6'>
						Step 2: Select Your Survey Options
					</Typography>
					<TextField
						value={surveyTitle}
						onChange={(e) => {
							setSurveyTitle(e.target.value);
						}}
						label='Survey Title'
					/>
					{/* <Typography variant='body1'>
						This determines how frequently we will send your survey to diners.
					</Typography> */}
					<Autocomplete
						disablePortal
						disableClearable
						options={frequencyOptions}
						getOptionLabel={(option) => option.label}
						isOptionEqualToValue={(option, value) =>
							option.label === value.label && option.days === value.days
						}
						renderInput={(params) => (
							<TextField {...params} label='Frequency' />
						)}
						onChange={handleFrequencyChange}
					/>
					{/* <Typography variant='body1'>
						This determines how many diners will receive your survey.
					</Typography> */}
					<Autocomplete
						disablePortal
						disableClearable
						options={participantOptions}
						getOptionLabel={(option) => option.label}
						isOptionEqualToValue={(option, value) =>
							option.label === value.label && option.days === value.days
						}
						renderInput={(params) => (
							<TextField {...params} label='Participants' />
						)}
						onChange={handleParticipantChange}
					/>
					<TextField
						value={rewardAmount}
						onChange={(e) => {
							setRewardAmount(e.target.value);
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>$</InputAdornment>
							),
						}}
						type='number'
						label='Reward Amount'
						inputProps={{ min: 1 }}
					/>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default SelectOptions;
