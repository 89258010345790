import {
	Card,
	CardContent,
	Container,
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Stack,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../services/auth-header.js';

const SurveyCard = ({ survey, enable, selectSurvey, onSurveyDeleted }) => {
	const navigate = useNavigate();
	const [openDialog, setOpenDialog] = useState(false);

	const onClick = () => {
		navigate(`/admin/survey/${survey.surveyId}`);
	};

	const handleDelete = (event) => {
		event.stopPropagation();
		setOpenDialog(true);
	};

	const handleDeleteConfirmed = async () => {
		try {
			const response = await axios.delete(`survey/${survey.surveyId}`);
			if (response.status === 200) {
				onSurveyDeleted();
			}
		} catch (err) {
			console.log(err);
		}
		setOpenDialog(false);
	};

	const handleCancelDelete = () => {
		setOpenDialog(false);
	};

	return (
		<>
			<Card
				variant='outlined'
				className='w-full overflow-visible mx-8 cursor-pointer'
				onClick={onClick}
				style={{
					width: '80%',
					cursor: 'pointer',
					boxShadow:
						'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
				}}>
				<CardContent>
					<Box
						display='flex'
						justifyContent='space-between'
						alignItems='center'>
						<Container>
							<Stack spacing={1}>
								<Typography variant='h6'>
									Title: {survey.surveyTitle}
								</Typography>
								<Typography variant='subtitle1'>
									Introduction: {survey.surveyIntro}
								</Typography>
								<Typography variant='body1'>
									Questions: {survey.questions.length}
								</Typography>
							</Stack>
						</Container>
						<Button onClick={handleDelete} variant='contained' color='error'>
							Delete
						</Button>
					</Box>
				</CardContent>
			</Card>
			<Dialog open={openDialog} onClose={handleCancelDelete}>
				<DialogTitle>Delete Survey</DialogTitle>
				<DialogContent>
					<p>
						Are you sure you want to delete this survey? This action cannot be
						undone.
					</p>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleCancelDelete}
						variant='contained'
						color='primary'>
						Cancel
					</Button>
					<Button
						onClick={handleDeleteConfirmed}
						variant='contained'
						color='error'>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SurveyCard;
