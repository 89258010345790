import {
	Button,
	Card,
	CardContent,
	Container,
	Stack,
	Avatar,
	Typography,
} from '@mui/material';
import axios from '../../../services/auth-header.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const InviteCard = ({ invitation, openDenyModal }) => {
	const [survey, setSurvey] = useState(null);
	const [restaurant, setRestaurant] = useState(null);
	const navigate = useNavigate();
	const createSurveyResponse = async () => {
		try {
			const surveyResponse = {
				surveyId: invitation.surveyId,
				dinerId: invitation.dinerId,
				restaurantId: invitation.restaurantId,
				expirationDate: invitation.expirationDate,
			};
			const result = await axios.post(
				`/survey/response/create`,
				surveyResponse
			);
			if (result) {
				navigate(`/survey/respond`);
			}
		} catch (error) {
			console.error('ERROR CREATING SURVEY RESPONSE:\n' + error);
		}
	};

	const accept = async () => {
		try {
			const result = await axios.post(
				`/survey/invite/accept/${invitation.surveyInvitationId}`
			);
			if (result) {
				await createSurveyResponse();
			}
		} catch (error) {
			console.log('ERROR ACCEPTING SURVEY:\n' + error);
		}
	};

	const getSurvey = async () => {
		try {
			const result = await axios.get(`survey/${invitation.surveyId}`);
			const surveyResponse = result.data;
			if (!surveyResponse) {
				setSurvey(null);
			} else {
				setSurvey(result.data);
			}
		} catch (error) {
			console.error('ERROR FETCHING SURVEY:\n' + error);
		}
	};

	const getRestaurant = async () => {
		try {
			const result = await axios.get(`restaurant/${invitation.restaurantId}`);
			const restaurantResponse = result.data;
			if (!restaurantResponse) {
				setRestaurant(null);
			} else {
				setRestaurant(result.data);
			}
		} catch (error) {
			console.error('ERROR FETCHING RESTAURANT:\n' + error);
		}
	};

	useEffect(() => {
		getRestaurant();
		getSurvey();
	}, []);
	console.log(survey);
	return (
		<Card
			variant='outlined'
			className='w-full overflow-visible py-4'
			style={{ maxWidth: '32rem' }}>
			<CardContent>
				<Container>
					<Stack>
						<Stack direction='row' spacing={4}>
							{restaurant && (
								<Avatar
									alt={`${restaurant.restaurantName} Logo`}
									src={
										process.env.REACT_APP_S3_BUCKET_URL + restaurant?.logoPath
									}
									sx={{ width: 72, height: 72 }}
								/>
							)}
							<Stack>
								<Typography variant='smTitle'>
									{restaurant && restaurant.restaurantName}
								</Typography>
								<Typography variant='md'>
									{/* Actually get addresses */}
									{restaurant &&
										`3370 Digital Dr #100 ${restaurant.city}, ${restaurant.stateAbbreviation} ${restaurant.zipCode}`}
								</Typography>
								<Typography variant='hsm' className='text-[#999999]'>
									4.3 miles away
								</Typography>
							</Stack>
						</Stack>
						<div className='border-[#C6C6C6] border-[0.5px] my-4'></div>
						<Typography variant='hsm' className='font-semibold'>
							Rewards
						</Typography>
						<Stack direction='row' className='justify-between'>
							<Typography variant='md'>Gift Card</Typography>
							<Typography className='self-center' variant='hsm'>
								{`$${survey?.rewardAmount} value`}
							</Typography>
						</Stack>
						<Typography variant='sm'>
							You will be able to choose from various different gift cards.
						</Typography>

						<Typography variant='hsm' className='font-semibold mt-3'>
							Length of the Survey
						</Typography>
						<Stack direction='row' className='justify-between'>
							<Typography variant='md'>{`${survey?.questions?.length} Questions`}</Typography>
							{/* Actually estimate time based off of some algorithm */}
							<Typography variant='hsm'>{`Estimate ${survey?.questions?.length} minutes`}</Typography>
						</Stack>
						{/* Actually get a tailored description */}
						<Typography variant='sm' className='text-[#555555]'>
							The survey contains multiple choice, long/short typing, and photo
							taking, so we suggest you to start the survey upon the arrival of
							the restaurant.
						</Typography>

						<div className='border-[#C6C6C6] border-[0.5px] my-4'></div>

						<div className='w-full flex justify-center mt-3 space-x-12'>
							<Button
								className='w-[7.938rem] h-[2.75rem]'
								variant='outlined'
								onClick={() => openDenyModal(invitation.surveyInvitationId)}>
								Reject
							</Button>
							<Button
								className='w-[7.938rem] h-[2.75rem]'
								variant='contained'
								onClick={accept}>
								Accept
							</Button>
						</div>
					</Stack>
				</Container>
			</CardContent>
		</Card>
	);
};

export default InviteCard;
